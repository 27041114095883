<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <div class="col-lg-12 text-right">
                    <v-btn class="" color="red ma-1" elevation="2" @click="$router.go(-1)" dark>BACK</v-btn><v-btn class="ma-1" color="primary" elevation="2" @click="PrintMPF()" dark><v-icon dark>mdi-printer</v-icon></v-btn>
                </div>
                <div id="divPrint">
                    
                    <!-- <div class="text-center">
                        <img src="https://svr02.kencana.org/viva-app/images/Logo-Kencana.png" alt="My Image" style="width: 50%;">
                    </div> -->
                    <div class="text-center"><b><h4>MULTI PURPOSE FORM</h4></b></div>
                    
                    <div>

                        <table width="100%" class="table table-bordered table-hover nowrap" style="font-size: 10px; border: 1px solid #000;">
                            <tr>
                                <td style="font-size: 12px;">TANGGAL</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printTanggalModel ? printTanggalModel : '-' }}</td>
                                <td style="font-size: 12px;">MPF ID</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printMPFIDModel ? printMPFIDModel : '-' }}</td>
                            </tr>
                            <tr>
                                <td style="font-size: 12px;">MPF SENDER</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printMPFSenderModel ? printMPFSenderModel : '-' }}</td>
                                <td style="font-size: 12px;">MPF RECEIVER</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printMPFReceiverModel ? printMPFReceiverModel : '-' }}</td>
                            </tr>
                            <tr>
                                <td style="font-size: 12px;">MPF SUBJECT</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printMPFSubjectModel ? printMPFSubjectModel : '-' }}</td>
                                <td style="font-size: 12px;">ORDER ID</td>
                                <td style="font-weight: bold; font-size: 12px;"> {{ printMPFOrderIDModel ? printMPFOrderIDModel : '-' }}</td>
                            </tr>
                            <tr>
                                <td height="100%" colspan="4">
                                    <div v-if="printMPFItemsList">
                                        Customer ID : {{ customerIDDetail ? customerIDDetail : '-' }} <br />
                                        Customer Name : {{ customerNameDetail ? customerNameDetail : '-' }} <br />
                                        Customer Address : {{ customerAddressDetail ? customerAddressDetail : '-' }} <br />
                                        Customer City : {{ customerCityDetail ? customerCityDetail : '-' }} <br />
                                        Items : <br />
                                        <ul>
                                            <li v-for="item in printMPFItemsList">
                                                {{ item.str }}
                                            </li>
                                        </ul>
                                    </div>
                                    <br />
                                    <div>
                                        Keterangan / Kronologi / Detail Komplain : <br />  
                                        <ul>
                                            <li v-for="item in printMPFRemarkList" :key="item.seq_no">
                                                {{ item.created_by }} : {{ item.message_body }}
                                            </li>
                                        </ul>
                                        <br />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold; font-size: 10px;" colspan="4" class="text-center">Semua data yang disampaikan dalam MPF ini diisi dengan BENAR dan dapat dipertanggung jawabkan oleh :</td>
                            </tr>
                            <tr>
                                <td colspan="3"> {{ printMPFStatusModel ? 'APPROVED BY : ' + printMPFStatusModel.toUpperCase() : '-' }}</td>
                                <td colspan="1" style="border: 1px solid black;"> Menyetujui </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td colspan="1" height="50px" style="border: 1px solid black;"></td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td colspan="1" style="border: 1px solid black;"> {{ printMPFReceiverModel ? printMPFReceiverModel : '-' }} </td>
                            </tr>
                        </table>

                    </div>
                    
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/',
                },
                {
                text: 'Blank',
                disabled: false,
                href: '#',
                },
                {
                text: 'Blank',
                disabled: true,
                href: '#',
                },
            ],
            printMPFIDModel: '',
            printTanggalModel: '',
            printMPFSenderModel: '',
            printMPFReceiverModel: '',
            printMPFSubjectModel: '',
            printMPFStatusModel: '',
            printMPFOrderIDModel: '',
            printMPFRemarkList: [],
            printMPFItemsList: [],
            customerIDDetail: '',
            customerNameDetail: '',
            customerAddressDetail: '',
            customerCityDetail: ''
        }
    },
    async mounted(){
        
        this.initialize()
        console.log(this.$route.params.id)

    },
    methods:{

        initialize(){

            this.$store.dispatch('setOverlay', true)

            this.printMPFIDModel = this.$route.params.id

            axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/printMPF`, { 
                    id : this.$route.params.id
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                
                // console.log(res.data.result);

                this.printTanggalModel = res.data.result.created_date
                this.printMPFSenderModel = res.data.result.sender_group_id + '(' + res.data.result.entity_id + ')'
                this.printMPFReceiverModel = res.data.result.receiver_group_id + '(' + res.data.result.receiver_entity_id + ')'
                this.printMPFSubjectModel = res.data.result.cat_desc
                this.printMPFStatusModel = res.data.result.approve1_by
                this.printMPFRemarkList = res.data.remark
                this.printMPFOrderIDModel = res.data.result.order_id
                this.printMPFItemsList = res.data.str
                this.customerIDDetail = res.data.customer['CustomerId']
                this.customerNameDetail = res.data.customer['NamaCustomer']
                this.customerAddressDetail = res.data.customer['Alamat']
                this.customerCityDetail = res.data.customer['Kota']
                // console.log(this.printMPFRemarkList);

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        
        PrintMPF(){

            this.$htmlToPaper('divPrint');

        }

    }
    
}
</script>

<style scoped>

        .tg  {border-collapse:collapse;border-spacing:0;}
         .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
         overflow:hidden;padding:2px 2px;word-break:normal;}
         .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
         font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
         .tg .tg-wa1i{font-weight:bold;text-align:center;vertical-align:middle}
         .tg .tg-0lax{text-align:left;vertical-align:top}

</style>